/**
 * Created by jingwen on 2021/8/19
 */
//小前台-C端：GatewayURL/general
const post: string = 'POST';
const get: string = 'GET';

/**
 * restful 接口结构
 */
export type apiItem = {
	url: string; //接口pathName
	method: string | undefined; //接口 method
	server: 'general' | 'acp' | 'mp' | 'marketing';
	[propName: string]: any; //扩展字段
};

//接口文档: http://yapi.wfx.wxfenxiao.com/project/594/interface/api/cat_9961

/**
 * 商家手机号
 */
export const mchPhone: apiItem = {
	url: '/mchPhone/info',
	method: get,
	server: 'general',
};

/**
 * 省市区列表
 */
export const regionList: apiItem = {
	url: '/region/list',
	method: get,
	server: 'general',
};

/**
 * 微信信息授权
 */
export const signIn: apiItem = {
	url: '/signIn',
	method: get,
	server: 'general',
};

/**
 * 是否登录有效
 */
export const isLogin: apiItem = {
	url: '/isLogin',
	method: get,
	server: 'general',
};

export const bindPhone: apiItem = {
	url: '/member/bindPhone',
	method: post,
	server: 'general',
};

/**
 * 静默授权登录
 */
export const silentSignIn: apiItem = {
	url: '/silentSignIn',
	method: get,
	server: 'general',
};

/**
 * h5登录
 */
export const h5SignIn: apiItem = {
	url: '/h5SignIn',
	method: get,
	server: 'general',
};

//获取验证码
export const verifyCode: apiItem = {
	url: '/verifyCode',
	method: post,
	server: 'general',
};

/**
 * 用户详情
 * http://yapi.wfx.wxfenxiao.com/project/594/interface/api/15478
 */
export const memberInfo: apiItem = {
	url: '/member/info',
	method: get,
	server: 'general',
};

/**
 * 签名
 */
export const wxSignature: apiItem = {
	url: '/wx/signature',
	method: get,
	server: 'general',
};
/**
 * 微信用户信息授权
 */
export const wxUpdate: apiItem = {
	url: '/wx/update',
	method: get,
	server: 'general',
};
/**
 * 微信重定向
 */
export const wxRedirectUri: apiItem = {
	url: '/wx/redirectUri',
	method: get,
	server: 'general',
};
/**
 * 商品详情页面
 * http://yapi.wfx.wxfenxiao.com/project/594/interface/api/14708
 */
export const goodsInfo: apiItem = {
	url: '/goods/info',
	method: get,
	server: 'general',
};

/**
 * 订单结算
 * http://yapi.wfx.wxfenxiao.com/project/594/interface/api/14958
 */
export const checkoutOrder = {
	url: '/order/CheckoutPage',
	method: post,
	server: 'general',
};
/**
 * 订单详情
 * http://yapi.wfx.wxfenxiao.com/project/594/interface/api/15300
 */
export const orderInfo: apiItem = {
	url: '/order/info',
	method: get,
	server: 'general',
};

/**
 * 提交订单
 * http://yapi.wfx.wxfenxiao.com/project/594/interface/api/14730
 */
export const orderSubmit: apiItem = {
	url: '/order/submit',
	method: post,
	server: 'general',
};

/**
 * 查询 收货地址详情
 */
export const addressInfo: apiItem = {
	url: '/member/addressInfo',
	method: get,
	server: 'general',
};
/**
 * 新增一个收货地址
 */
export const newAddress: apiItem = {
	url: '/member/address/add',
	method: post,
	server: 'general',
};
/**
 * 更新收货地址
 */
export const updateAddress: apiItem = {
	url: '/member/address/update',
	method: post,
	server: 'general',
};

/**
 * 我的订单
 */
export const orderList: apiItem = {
	url: '/order/list',
	method: post,
	server: 'general',
};
/**
 * 取消订单
 */
export const cancelOrder: apiItem = {
	url: '/order/cancel',
	method: post,
	server: 'general',
};

/**
 * 付款
 * http://yapi.wfx.wxfenxiao.com/project/594/interface/api/14728
 */
export const payOrder: apiItem = {
	url: '/order/pay',
	method: post,
	server: 'general',
};

/**
 * 确认收货
 * http://yapi.wfx.wxfenxiao.com/project/594/interface/api/15296
 */
export const confirmOrder: apiItem = {
	url: '/order/confirm',
	method: post,
	server: 'general',
};

/**
 * 订单物流
 */
export const logisticsOrder: apiItem = {
	url: '/order/express',
	method: get,
	server: 'general',
};

/**
 * 商品小票
 */
export const mchReceiptWx: apiItem = {
	url: '/wx/mchReceipt',
	method: post,
	server: 'general',
};

// 点金计划活动营销拼团
export const groupInfo: apiItem = {
	url: '/order/spotGoldPlan',
	method: get,
	server: 'marketing',
};

// 点金计划活动营销拼团
export const MarketingWxSignature: apiItem = {
	url: '/wx/signature',
	method: get,
	server: 'marketing',
};

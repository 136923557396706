import React, { useMemo } from 'react';
import styles from './index.module.scss';

interface PriceProps {
	num: number | string;
	showUnit?: boolean;
	size?: 'xlarge' | 'large' | 'middle' | 'small';
	color?: string;
}
/**
 * @params size
 * xlarge: 支付结果页，价格符号和小数点后面数字字号20px，价格整数位字号32px，符号和数字间距4px
 * large: 价格符号和小数点后面数字字号15px，价格整数位字号24px，符号和数字间距4px
 * middle: 价格符号和小数点后面数字字号12px，价格整数位字号20px，符号和数字间距2px
 * small: 价格符号和小数点后面数字字号10px，价格整数位字号16px，符号和数字间距2px
 */

const Price: React.FC<PriceProps> = ({
	num,
	showUnit = true,
	size = 'middle',
	color,
}) => {
	// if (!(typeof num == 'string' || typeof num == 'number')) {
	// 	return null;
	// }
	const priceArr = useMemo(() => {
		const arrTemp = String(num)?.split('.');
		if (arrTemp.length === 1) {
			arrTemp.push('00');
		} else if (arrTemp.length === 2 && arrTemp[1].length === 1) {
			arrTemp[1] = arrTemp[1] + '0';
		}
		return arrTemp;
	}, [num]);

	return (
		<span className={`${styles[size]} number-font`} style={{ color }}>
			{showUnit ? <span className={styles.unit}>¥</span> : null}
			<span className={styles.integer}>{priceArr[0]}</span>.
			<span>{priceArr[1]}</span>
		</span>
	);
};

export default Price;

import React, { useState, useEffect } from 'react';
import { Popup, Toast } from 'antd-mobile-v5';
import { CustomIcon } from '../index';
import { Price, Image, Stepper, PhotoView } from '../index';
import styles from './index.module.scss';
import classNames from 'classnames';
import Util from '../../util/util';

const ICON_SOLDOUT = '';
interface ITitleProps {
	visible: boolean;
	goodsDetail: any;
	closeSkuPopup: (params?: any) => void;
	confirmSku: (params: any) => void;
	selectedSku?: string[];
	bthDisabled?: boolean;
	setShowLoginDialog: (e: boolean) => void;
}
/*
	selectedSku：默认选择的sku的appGoodsAttributeValueUuid组成的数组['1469242550440689666', '1469242550440689670']
	goodsDetail：商品详情数据
	closeSkuPopup：点击关闭按钮
	confirmSku：点击确定
*/

const SkuPopup: React.FC<ITitleProps> = ({
	visible,
	selectedSku,
	goodsDetail,
	closeSkuPopup,
	confirmSku,
	bthDisabled = false,
	setShowLoginDialog,
}) => {
	// if (!goodsDetail || !visible) {
	// 	return null;
	// }

	const { attributeList, skuList, goodsCarouselList } = goodsDetail;
	const [inputNum, setInputNum] = useState<number>(1);
	const [maxStock, setMaxStock] = useState<number>(1);
	const updateMaxStock = (v: string | number) => {
		setMaxStock(Number(v));
	};
	const [skuDataArr, setSkuDataArr] = useState<any>(() => {
		if (attributeList) {
			return JSON.parse(JSON.stringify(attributeList));
		} else {
			return [];
		}
	});
	const [topImg, setTopImg] = useState<string>(() => {
		if (goodsCarouselList && goodsCarouselList.length > 0) {
			return goodsCarouselList[0]?.url;
		}
		return '';
	});
	const [topImgIndex, setTopImgIndex] = useState<number>(0);

	// sku图片放大预览
	const [imgUrls, setImgUrls] = useState<any[]>([]);
	const [showPreview, setShowPreview] = useState<boolean>(false);
	const [currentImg, setCurrentImg] = useState<number>(0);

	// 已选择的sku的信息
	const [selectedSkuArr, setSelectedSkuArr] = useState<any[]>([]);

	const [selectedSkuTxt, setSelectedSkuTxt] = useState<string>('');

	// 已选择sku对应的规格的index
	const [selectedAttrIndex, setSelectedAttrIndex] = useState<any[]>([]);

	const [finalSku, setFinalSku] = useState<any>(null);

	// 预览图片
	const handlePreview = (imgIndex: number) => {
		if (imgUrls?.length > 0) {
			setShowPreview(true);
			setCurrentImg(imgIndex);
		}
	};
	// const setCurrent = (n: number) => {
	// 	setCurrentImg(n);
	// };
	// const setShow = () => {
	// 	setShowPreview(false);
	// };

	// 判断数组arrB是否完全包含数组arrA
	const isInclude = (arrA: any[], arrB: any[]) => {
		return arrA.every((a: any) => {
			return arrB.some((b: any) => {
				return a === b;
			});
		});
	};

	// 判断是否每组规格都选择sku
	const isComplete = (skuArr: any[]) => {
		const totalLen = skuDataArr.length;
		const selectedLen = skuArr.filter((item) => item).length;
		return totalLen === selectedLen;
	};

	const transSelectedSku = (skuArr: any[]) => {
		const skuIds: any = [];
		skuArr.forEach((item: any) => {
			skuIds.push(item.appGoodsAttributeValueUuid);
		});
		const _skuList = skuList.find((item: any) =>
			isInclude(item.attributeValueIds, skuIds),
		);
		if (_skuList) {
			updateMaxStock(_skuList.stockNum);
			if (_skuList.stockNum - inputNum < 0) {
				setInputNum(_skuList.stockNum);
			}
			setFinalSku(_skuList);
		}
	};

	// 计算sku库存
	const checkStock = (
		subItem: any,
		subIndex: number,
		index: number, // 当前规格的的index
		skuArr: any[], // 已选择的sku的信息
		attrIndex: any[], // 已选规格的index数组
	) => {
		const tempArr = [...skuDataArr];
		tempArr.forEach((ele, i) => {
			// 除当前规格外，其他规格的sku都要重新计算库存
			// attributeList.length==1 只有一组规格时，当前组要重新计算
			if (index !== i || attributeList.length === 1) {
				ele?.attributeValues.forEach((eleItem: any, eleIndex: number) => {
					let num = 0;
					skuList.forEach((listItem: any) => {
						const skuIdArr: any = [];
						const skuArrTemp = JSON.parse(JSON.stringify(skuArr));
						// 如果当前组规格已选择，计算库存时，不用包含当前组已选sku
						const tempIndex = attrIndex.indexOf(i);
						if (tempIndex >= 0) {
							skuArrTemp.splice(tempIndex, 1);
						}
						skuArrTemp.forEach((skuArrItem: any) => {
							if (skuArrItem?.appGoodsAttributeValueUuid) {
								skuIdArr.push(skuArrItem?.appGoodsAttributeValueUuid);
							}
						});
						if (
							isInclude(skuIdArr, listItem.attributeValueIds) &&
							listItem.attributeValueIds.indexOf(
								eleItem.appGoodsAttributeValueUuid,
							) >= 0
						) {
							num += listItem.stockNum;
						}
					});
					eleItem.stockNum = num;
				});
			}
		});

		if (isComplete(skuArr)) {
			transSelectedSku(skuArr);
		} else {
			setFinalSku(null);
		}
		return tempArr;
	};

	// 点击sku时
	const selectSku = (subItem: any, subIndex: number, index: number) => {
		const tempArr = [...skuDataArr];
		let curItem: any = [];
		const skuArr = [...selectedSkuArr];
		const attrIndex = [...selectedAttrIndex];
		tempArr[index]?.attributeValues?.forEach((ele: any, eleIndex: number) => {
			if (
				ele.appGoodsAttributeValueUuid === subItem.appGoodsAttributeValueUuid
			) {
				ele.active = !ele.active;
				curItem = ele;
				if (ele.active) {
					attrIndex[index] = index;
					skuArr[index] = curItem;
					if (tempArr[index]?.isUseImage === 1) {
						setTopImg(ele.imgUrl);
						setTopImgIndex(eleIndex + 1);
					}
				} else {
					attrIndex[index] = '';
					skuArr[index] = null;
					if (
						tempArr[index].isUseImage === 1 &&
						goodsCarouselList?.length > 0
					) {
						setTopImg(goodsCarouselList[0].url);
						setTopImgIndex(1);
					}
				}
			} else {
				ele.active = false;
			}
		});
		setSkuDataArr(checkStock(subItem, subIndex, index, skuArr, attrIndex));
		setSelectedSkuArr(skuArr);
		setSelectedAttrIndex(attrIndex);
	};

	const handleClose = () => {
		closeSkuPopup();
	};
	const handleConfirm = async (type?: string) => {
		if (bthDisabled) {
			return false;
		}

		const r = await Util.userIsLogin();
		// const satoken = Util.needLogin();
		const satoken = r.code;

		if (!!satoken) {
			// h5 弹出注册登录
			setShowLoginDialog(true);
			closeSkuPopup();
			return;
		}
		const isWx = Util.getSystemEnv();
		console.log('skupopup isWx:', isWx);
		if (isWx) {
			const phone = window.localStorage.getItem('phone');
			if (!phone) {
				setShowLoginDialog(true);
				closeSkuPopup();
				return;
			}
		}
		if (isComplete(selectedSkuArr)) {
			confirmSku({
				appGoodsUuid: goodsDetail.appGoodsUuid,
				finalSku: finalSku,
				num: inputNum,
			});
		} else {
			Toast.show({
				content: '请选择规格',
				icon: 'fail',
			});
		}
	};

	// 初始计算库存
	const initStock = (selectedSkus: any) => {
		const skuArr: any[] = [];
		const attrIndex: any[] = [];
		let skuDataArrTemp: any[] = [];

		selectedSkus.forEach((skuId: string) => {
			if (skuId) {
				attributeList.forEach((item: any, index: number) => {
					item?.attributeValues.forEach((subItem: any, subIndex: number) => {
						if (skuId === subItem.appGoodsAttributeValueUuid) {
							skuArr[index] = subItem;
							attrIndex[index] = index;
							skuDataArrTemp = checkStock(
								subItem,
								subIndex,
								index,
								skuArr,
								attrIndex,
							);
							skuDataArrTemp[index].attributeValues[subIndex].active = true;

							if (skuDataArrTemp[index].isUseImage === 1) {
								setTopImg(
									skuDataArrTemp[index].attributeValues[subIndex].imgUrl,
								);
								setTopImgIndex(subIndex + 1);
							}
						}
					});
				});
			}
		});
		setSkuDataArr(skuDataArrTemp);
		setSelectedSkuArr(skuArr);
		setSelectedAttrIndex(attrIndex);
	};

	// 没有默认选中时计算每个sku的初始库存
	const noSelectedStock = () => {
		const skuArr: any[] = [];
		const attrIndex: any[] = [];
		let skuDataArrTemp: any[] = [];
		attributeList.forEach((item: any, index: number) => {
			item?.attributeValues.forEach((subItem: any, subIndex: number) => {
				skuDataArrTemp = checkStock(subItem, subIndex, -1, skuArr, attrIndex);
			});
		});
		setSkuDataArr(skuDataArrTemp);
	};

	useEffect(() => {
		if (goodsDetail?.skuList && goodsDetail?.skuList.length > 0) {
			if (selectedSku && selectedSku.length > 0) {
				initStock(selectedSku);
			} else {
				noSelectedStock();
			}
		} else {
			updateMaxStock(goodsDetail?.stockNum);
		}
	}, [selectedSku, goodsDetail]);

	useEffect(() => {
		let urls: {
			id: string | number;
			imageUrl: string;
		}[] = [];
		urls.push(goodsDetail?.goodsCarouselList?.[0].url);
		if (goodsDetail?.attributeList?.length > 0) {
			const imgAttrs = goodsDetail?.attributeList.find(
				(item: any) => item.isUseImage === 1,
			);
			if (imgAttrs) {
				imgAttrs?.attributeValues.forEach((imgItem: any, imgIndex: number) => {
					urls.push(imgItem.imgUrl || goodsDetail?.goodsCarouselList?.[0].url);
				});
			}
		}
		setImgUrls(urls);
	}, []);

	useEffect(() => {
		let tempArr: string[] = [];
		selectedSkuArr.forEach((item) => {
			if (item) {
				tempArr.push(item.name);
			}
		});
		setSelectedSkuTxt(tempArr.join(';'));
	}, [selectedSkuArr]);

	const marketPrice = finalSku?.marketPrice || goodsDetail.marketPrice;

	return (
		<>
			<Popup
				visible={visible}
				// scrollY
				className={styles.skuPopupWrap}
				afterClose={() => handleClose()}
				onMaskClick={() => handleClose()}
			>
				<div className={styles.skuPopupContent}>
					<div className={styles.header}>
						<div className={styles.headerInfo}>
							<Image
								src={topImg}
								defaultSrc={goodsDetail?.goodsCarouselList?.[0]?.url}
								className={styles.goodsImg}
								onClick={() => handlePreview(topImgIndex)}
							/>
							<div className={styles.rightInfo}>
								<div className={styles.goodsPrice}>
									{finalSku?.currentPrice ? (
										<Price num={finalSku.currentPrice} />
									) : goodsDetail?.currentPriceMin &&
									  goodsDetail?.currentPriceMax &&
									  goodsDetail?.currentPriceMin !==
											goodsDetail?.currentPriceMax ? (
										<div className={styles.priceRange}>
											<Price num={goodsDetail.currentPriceMin} />
											<span style={{ color: '#f62430' }}>～</span>
											<Price
												num={goodsDetail?.currentPriceMax}
												showUnit={false}
											/>
										</div>
									) : (
										<Price num={goodsDetail?.currentPrice} />
									)}
									{marketPrice !== '0.00' && (
										<span className={`${styles.original} number-font`}>
											¥{marketPrice}
										</span>
									)}
								</div>
								{selectedSkuTxt && (
									<div className={styles.selectedTxt}>
										已选择：{selectedSkuTxt}
									</div>
								)}
							</div>
						</div>
						<div className={styles.closeIcon} onClick={() => handleClose()}>
							<CustomIcon type='Mobile-Cancel' size={34} color='#979797' />
						</div>
					</div>
					{skuDataArr?.length > 0 &&
						skuDataArr?.map((item: any, index: number) => (
							<div className={styles.conItem} key={item.appGoodsAttributeUuid}>
								<div className={styles.skuTitle}>{item.name}</div>
								<div className={styles.skuCon}>
									{item.attributeValues?.map(
										(subItem: any, subIndex: number) => (
											<div
												className={classNames(styles.skuItem, {
													[styles.active]: subItem.active,
													[styles.disabled]: subItem.stockNum <= 0,
												})}
												key={subItem.appGoodsAttributeValueUuid}
												onClick={() => {
													if (subItem?.stockNum > 0) {
														selectSku(subItem, subIndex, index);
													}
												}}
											>
												{item.isUseImage === 1 && subItem.imgUrl && (
													<Image
														src={subItem.imgUrl}
														className={styles.skuImg}
													/>
												)}
												<span>{subItem.name}</span>
												{subItem?.stockNum <= 0 && (
													<div
														className={styles.soldOut}
														// style={{ backgroundImage: `url(${ICON_SOLDOUT})` }}
													></div>
												)}
											</div>
										),
									)}
								</div>
							</div>
						))}
					<div className={styles.inputNumer}>
						<div className={styles.label}>
							购买数量
							<span className={styles.stockNum}>
								库存：{finalSku?.stockNum || goodsDetail?.stockNum}件
							</span>
						</div>
						<Stepper
							max={maxStock}
							value={inputNum}
							changeCallBack={(n: number) => setInputNum(Number(n))}
							blurCallBack={(event: any) => {
								const val = event;
								// const val = event.detail.value;
								const num = val > maxStock ? maxStock : val;
								setInputNum(num);
							}}
							stockNum={Number(finalSku?.stockNum || goodsDetail?.stockNum)}
						/>
					</div>
					<div
						className={`${styles.bottomBtn} ${bthDisabled ? styles.gray : ''}`}
					>
						<div
							className={styles.btn}
							onClick={() => {
								handleConfirm('buy');
							}}
						>
							{goodsDetail?.sonState === 23 ? '已售罄' : '立即购买'}
						</div>
					</div>
				</div>
			</Popup>

			{showPreview && (
				<PhotoView
					visible={showPreview}
					current={currentImg}
					images={imgUrls}
					onClose={() => setShowPreview(false)}
				/>
			)}
		</>
	);
};

export default SkuPopup;

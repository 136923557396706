import React, {
	useEffect,
	useState,
	PropsWithChildren,
	useCallback,
} from 'react';
import styles from './layoutPage.module.scss';
import { withRouter } from 'react-router-dom';
import Util from '../../util/util';

type Props = {
	className?: string;
	[propName: string]: any;
} & PropsWithChildren<any>;

function LayoutPage(props: Props): JSX.Element {
	const [isIphoneX] = useState<Boolean>(() => {
		return Util.isIphoneX();
	});
	const { className } = props;
	const [classNameList, setClassNameList] = useState<string>('');

	const calcLayout = useCallback(() => {
		if (isIphoneX) {
			if (className) {
				setClassNameList(`${styles.layoutPage}  ${className}`);
			} else {
				setClassNameList(`${styles.layoutPage} ${styles.isPhoneX}`);
			}
		} else {
			setClassNameList(`${styles.layoutPage} ${className}`);
		}
	}, [isIphoneX, className]);

	useEffect(() => {
		calcLayout();
		if (Util.getSystemEnv()) {
			// console.log('layoutPage location', window.location);
		} else {
			Util.h5Init();
		}
	}, [calcLayout]);

	return <div className={classNameList}>{props.children}</div>;
}

export default withRouter(LayoutPage);

/**
 * Created by jingwen on 2021/9/3
 */
//@ts-nocheck
/* eslint-disable */
import { FC, Fragment, HTMLAttributes, useEffect, useState } from 'react';
import styles from './scrollTop.module.scss';

type ScrollTopProps = HTMLAttributes<HTMLButtonElement> & {
	top?: number;
	[key: string]: any;
	ok?: () => void;
};

function scrollTop() {
	window.scrollTo({
		top: 0,
		behavior: 'smooth',
	});
}

const ScrollTop: FC<ScrollTopProps> = ({ top = 20, ok, children }) => {
	const [visible, setVisible] = useState(false);

	function onScroll() {
		setVisible(document.documentElement.scrollTop > top);
	}

	useEffect(() => {
		document.addEventListener('scroll', onScroll);
		return () => document.removeEventListener('scroll', onScroll);
	}, []);

	return (
		<Fragment>
			{visible && (
				<div
					onClick={() => {
						scrollTop();
						ok && ok();
					}}
					className={styles.scrollTop}>
					{children || (
						<div className={styles.defaultIcon}>
							<img
								src='https://qimai-1251581441.cos.ap-shanghai.myqcloud.com/qimai-h5/%E7%BC%96%E7%BB%84%205%402x.png'
								alt=''
							/>
						</div>
					)}
				</div>
			)}
		</Fragment>
	);
};

export default ScrollTop;

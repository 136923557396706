/**
 * Created by jingwen on 2021/8/23
 */

import {
	FC,
	Fragment,
	useState,
	useRef,
	useEffect,
	memo,
	useMemo,
} from 'react';
import styles from './loginDialog.module.scss';
import { CustomIcon } from '../index';
import Countdown from 'react-countdown';
import { verifyCode as postCode } from '../../util/apiList';
import { fetch } from '../../util/request';
import Util from '../../util/util';

const CaptchaAppId: string = '2099689224';

interface LoginProps {
	title: string;
	btnText: string;
	visible: boolean;
	host?: string;
	getCode?: () => void; //发送手机验证码
	onClose: () => void; // 关闭
	reCall?: (code?: number) => void; // 登录成功后回调
}

const TCUrl: string = 'https://ssl.captcha.qq.com/TCaptcha.js';

const LoginDialog: FC<LoginProps> = (props) => {
	const { onClose, title, btnText, reCall, visible, host, ...ext } = props;
	console.log(1, ext);
	const phoneReg = /^1[23456789]\d{9}$/;
	const codeReg = /^\d{6}$/;

	const phoneRef = useRef<HTMLInputElement | null>(null);

	const [startTime, setStartTime] = useState<boolean>(false);
	const [phone, setPhone] = useState<string>('');
	const [verifyCode, setVerifyCode] = useState<string>('');
	const [errorMsg, setErrorMsg] = useState<string>('');
	const [aiCode, setAiCode] = useState<any>(null);

	function verifyPhone(s: string | undefined): boolean {
		const _t = s && phoneReg.test(s);
		if (_t) {
			phoneRef.current?.blur();
		}
		return _t as boolean;
	}

	function handleVerifyCode(s: string | undefined): boolean {
		const _t = s && codeReg.test(s);
		return _t as boolean;
	}

	const callback = function (res: any) {
		/* res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
     res（客户端出现异常错误 仍返回可用票据） = {ret: 0, ticket: "String", randstr: "String",
                                           errorCode: Number, errorMessage: "String"}
     res（用户主动关闭验证码）= {ret: 2}
     */
		// appid: "2099689224"
		// bizState: undefined
		// randstr: "@SBv"
		// ret: 0
		// ticket: "t03-I9NPahfM5UB7PAp9D1-45Ln-r7dpp9P9pUp83RDGsxPRoXinPfsh8WycE16noN7bryYwsv_D91eHvyxkv2VZYH3RwnPPUs2a5X2ZGTwqSo*"
		if (res.ticket && res.randstr && !res.ret) {
			// 上传票据 可根据errorCode和errorMessage做特殊处理或统计
			setAiCode({ ...res });
			setStartTime(true);
		}
	};

	function loadErrorCallback() {
		/* 生成票据或自行做其它处理 */
		const ticket: string =
			'terror_1001_' + CaptchaAppId + Math.floor(new Date().getTime() / 1000);
		callback({
			ret: 0,
			randstr: '@' + Math.random().toString(36).substr(2), //本次验证的随机串， 请求后台接口时需带上
			ticket,
			errorCode: 1001,
			errorMessage: 'jsload_error',
		});
	}

	type Tencent = {
		[propName: string]: any;
	};

	/**
	 * 验证AiCode
	 */
	function verifyAiCode(): void {
		const { TencentCaptcha }: Tencent = window;
		try {
			const captcha1 = new TencentCaptcha(CaptchaAppId, callback);
			captcha1.show(); // 显示验证码
		} catch (error) {
			loadErrorCallback();
		}
	}

	/**
	 * 请求登录
	 */
	async function sendLoginData() {
		const res: any = await Util.getH5Login(phone, verifyCode, host);
		if (!res?.code) {
			onClose();
		} else {
			if (res.code === 10500 || res.code === 500500) {
				setErrorMsg(res.msg);
			}
		}
		reCall && reCall(res.code);
	}

	async function handleLogin() {
		if (phoneReg.test(phone) && codeReg.test(verifyCode) && !aiCode?.randstr) {
			setErrorMsg('请获取验证码');
			return false;
		}
		if (
			!verifyCode ||
			!aiCode?.randstr ||
			!handleVerifyCode(verifyCode) ||
			!phone
		) {
			return false;
		}

		sendLoginData().catch(() => '');
	}

	/**
	 * 取服务端验证码
	 */
	async function getVerifyCode() {
		let _currentApi = { ...postCode };
		if (host) {
			_currentApi.url = host + '/' + postCode.server + postCode.url;
		}

		await fetch({
			..._currentApi,
			data: {
				phone: phone,
				randStr: aiCode.randstr,
				ticket: aiCode.ticket,
			},
		});
	}

	useEffect(() => {
		if (startTime) {
			getVerifyCode().catch();
		}
		//eslint-disable-next-line
	}, [startTime]);

	const memoCustomDown = useMemo(() => {
		return (
			<Countdown
				date={Date.now() + 59 * 1000}
				onComplete={({ completed }) => {
					if (completed) {
						setStartTime(false);
					}
				}}
				renderer={({ seconds, completed }) => {
					if (completed) {
						return (
							<div
								className={phone && aiCode ? styles.act : ''}
								onClick={() => {
									if (!verifyPhone(phone) || !aiCode) {
										return false;
									}
									if (!startTime) {
										setStartTime(true);
									}
								}}>
								获取验证码
							</div>
						);
					} else {
						return <div>{seconds}s后再次获取</div>;
					}
				}}
			/>
		);
		// eslint-disable-next-line
	}, [startTime]);

	useEffect(() => {
		setErrorMsg('');
	}, [verifyCode]);

	const J_TCURL = useMemo(() => {
		return document.getElementById('J_TCUrl');
		// eslint-disable-next-line
	}, [visible]);

	useEffect(() => {
		if (visible && !J_TCURL) {
			const script = document.createElement('script');
			script.setAttribute('src', TCUrl);
			script.setAttribute('id', 'J_TCUrl');
			document.head.appendChild(script);
		}
		// eslint-disable-next-line
	}, [visible]);

	return (
		<Fragment>
			{props?.visible && (
				<div className={styles.dialogBox}>
					<section className={styles.body}>
						<div
							className={styles.closeIcon}
							onClick={() => {
								onClose();
							}}>
							<CustomIcon type={'Mobile-cancel3'} size={20} color='#999' />
						</div>
						<div className={styles.content}>
							<div className={styles.tt}>{title}</div>
							<div className={styles.labelBox}>
								<span className={styles.label}>手机号码</span>
								<div className={styles.input}>
									<input
										placeholder='请输入手机号码'
										type={'number'}
										ref={phoneRef}
										maxLength={11}
										value={phone}
										onChange={(e) => {
											let _s = e.target.value.slice(0, 11);
											// @ts-ignore
											setPhone(_s);
											const _t = e.target.value.toString();
											verifyPhone(_t);
										}}
										onBlur={(e) => {
											let _t = phoneReg.test(e.target.value);
											if (!_t) {
												setErrorMsg('手机号码不正确');
											} else {
												setErrorMsg('');
											}
										}}
									/>
								</div>
							</div>
							<div className={styles.labelBox}>
								<span className={styles.label}>验证码</span>
								<div className={styles.input}>
									<input
										className={styles.code}
										placeholder='请输入验证码'
										type={'number'}
										maxLength={6}
										value={verifyCode}
										onChange={(e) => {
											let _t = e.target.value.slice(0, 6);
											setVerifyCode(_t);
											handleVerifyCode(_t);
										}}
										onBlur={(e) => {
											let _t = codeReg.test(e.target.value);

											if (e.target.value && !_t) {
												setErrorMsg('验证码错误');
											} else if (!e.target.value) {
												setErrorMsg('请输入验证码');
											} else {
												setErrorMsg('');
											}
										}}
									/>

									<div className={`${styles.telBtn}`}>
										{startTime ? (
											memoCustomDown
										) : (
											<div
												className={verifyPhone(phone) ? styles.act : ''}
												onClick={() => {
													if (!verifyPhone(phone)) {
														return false;
													}
													verifyAiCode();
												}}>
												获取验证码
											</div>
										)}
									</div>
								</div>
							</div>
							<div className={styles.error}>{errorMsg ? errorMsg : ''}</div>
							<div className={styles.submit} onClick={handleLogin}>
								{btnText}
							</div>
						</div>
					</section>
				</div>
			)}
		</Fragment>
	);
};

LoginDialog.defaultProps = {
	visible: false,
	title: '登录',
	btnText: '登录',
	onClose: () => {},
};

export default memo(LoginDialog);

import { FC, PropsWithChildren } from 'react';
import { createFromIconfontCN } from '@ant-design/icons';

import * as React from 'react';
import Util from '../../util/util';

function px2vw(
	px: number,
	color: string,
	width?: number,
	height?: number,
): any {
	// const baseWidth = 750;

	return px
		? {
				fontSize: Util.px2vw(px),
				overflow: 'hidden',
				color: color,
		  }
		: {};
}

type Props = {
	type: string;
	color?: string;
	size?: number;
	onClick?: React.MouseEventHandler<SVGSVGElement>;
} & PropsWithChildren<any>;

const IconFont = createFromIconfontCN({
	scriptUrl: ['//at.alicdn.com/t/font_2752526_hay5eyvt1yv.js'],
});

/**
 * Custom Icon
 * @param props
 * @constructor
 */
const CustomIcon: FC<Props> = (props) => {
	const { size, type, color, onClick } = props;
	const _style = size && px2vw(size, color);
	return (
		<IconFont
			type={type}
			data-size={size}
			onClick={() => {
				onClick && onClick();
			}}
			style={_style}
		/>
	);
};

CustomIcon.defaultProps = {
	color: '#000',
	size: 15,
	type: '',
};
export default CustomIcon;

/**
 * Created by jingwen on 2021/8/24
 */

import { FC, Fragment, PropsWithChildren, useState } from 'react';
import { PhotoSlider } from 'react-photo-view';
import 'react-photo-view/dist/index.css';
import * as React from 'react';
import { IPhotoSliderProps } from 'react-photo-view/dist/PhotoSlider';
import './photoView.css';

const defaultImgUrl =
	'https://qimai-1251581441.cos.ap-shanghai.myqcloud.com/corsSource/default/goods.jpeg';

type Props = {
	current: number;
	visible: boolean;
	images: string[];
	onClose?: IPhotoSliderProps;
} & PropsWithChildren<any>;

const PhotoView: FC<Props> = (props) => {
	const [showPhoto] = useState<boolean>(props.visible);
	const [currentIndex, setCurrentIndex] = useState(props.current);
	const [imagesList] = useState<string[]>(props.images);

	return (
		<Fragment>
			{showPhoto && (
				<PhotoSlider
					maskClassName={'parentBox'}
					visible={showPhoto}
					maskClosable={true}
					onClose={() => {
						setTimeout(() => {
							props.onClose();
						}, 200);
						return false;
					}}
					photoClosable={true}
					images={imagesList.map((item) => ({ src: item }))}
					onIndexChange={setCurrentIndex}
					index={currentIndex}
					bannerVisible={true}
					introVisible={true}
					brokenElement={<img src={defaultImgUrl} alt='' />}
				/>
			)}
		</Fragment>
	);
};
PhotoView.defaultProps = {
	current: 0,
	visible: false,
	images: [],
	onClose: () => {},
};
export default PhotoView;

/**
 * Created by jingwen on 2021/8/19
 */
//@ts-nocheck
/* eslint-disable */

import { fetch } from './request';
import {
	apiItem,
	bindPhone,
	h5SignIn,
	isLogin,
	mchPhone,
	memberInfo,
	silentSignIn,
	wxSignature,
	wxUpdate,
} from './apiList';
import { Toast } from 'antd-mobile';

/**
 * 设置页面标题
 * @param title
 */
function setTitle(title: string): void {
	document.title = title;
}

/**
 * 是否是流海屏
 */
function isIphoneX() {
	const { availHeight } = window.screen;

	return availHeight >= 724 && getSystemEnv() && window.history.length === 1;
}

/**
 * 是否是微信环境
 */
function getSystemEnv(): boolean {
	return window.navigator.userAgent.toLowerCase().includes('micromessenger');
}

interface SigninProps {
	code: string;
	isFirst: true;
}

/**
 * 微信用户登录 ，取token
 */
async function getSilentSignIn(
	options: SigninProps,
	cb: Function,
): Promise<void> {
	const _obj = options.isFirst ? silentSignIn : wxUpdate;
	const { code, data } = await fetch({
		..._obj,
		data: {
			code: options.code,
		},
	});

	if (!code && data?.satoken) {
		cb && cb(data.satoken);
	} else if (!code && !data && !options.isFirst) {
		cb && cb(data.satoken);
	} else if (code === 10500) {
		window.localStorage.clear();
		exceptionStore(code);
	}
}

/**
 * 静默授权，获取 code
 */
function getWxCode(scopeString: string = 'snsapi_base') {
	const isWx = getSystemEnv();
	if (isWx) {
		const appId = Util.appId; //'wx1fae6a3423c91cc8';
		const scope = scopeString; //应用授权作用域，snsapi_base （不弹出授权页面，直接跳转，只能获取用户openid），snsapi_userinfo （弹出授权页面，可通过openid拿到昵称、性别、所在地。
		const _currentScope = scopeString === 'snsapi_base' ? 'base' : 'userinfo';

		let _search = objToSearch({
			appGoodsUuid: getRouteKey('appGoodsUuid'),
			mch: getRouteKey('mch'),
			mpAppUuid: getRouteKey('mpAppUuid'),
		});

		let url = window.location.origin + window.location.pathname + _search;

		const redirect_uri = encodeURIComponent(url);

		//设置请求授权方式
		window.localStorage.setItem('scope', _currentScope);

		const response_type = 'code'; //返回类型
		const state = Math.random().toString(16).substr(9);
		const wxUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}&state=${state}#wechat_redirect`;

		if (window.location.host.includes('fuwuapi.com')) {
			window.location.href = wxUrl;
		}
	}
}

/**
 * 取用户信息
 */
async function getUserInfo(cb?: Function) {
	const r: any = await userIsLogin();
	console.log('isLogin', r);

	if (r?.code) {
		if (getSystemEnv()) {
			cb && cb({ code: 10401 });
		} else {
			window.localStorage.setItem('needLogin', '1');
			cb && cb({ code: 10403 });
		}
		return false;
	}

	const { code, data } = await fetch({
		...memberInfo,
	});
	if (!code && data) {
		window.localStorage.setItem('userInfo', JSON.stringify(data));
		window.localStorage.setItem('phone', data?.phone || '');
		window.localStorage.setItem('nickname', data?.nickname || '');
	}
	window.localStorage.setItem('needLogin', String(+!!code));

	cb && cb({ code, data });
}

async function userIsLogin() {
	const res: any = await fetch({
		...isLogin,
	});
	return new Promise((resolve) => {
		resolve(res);
	});
}

function needLogin(): number {
	//1 需登录，0 已登录
	// @ts-ignore
	return +window.localStorage.getItem('needLogin');
}

/**
 * 根据环境返回api
 * @param obj
 * @param host
 */
function getNewApi(obj: apiItem, host: string): apiItem {
	let _currentApi = { ...obj };
	if (host) {
		_currentApi.url = host + '/' + _currentApi.server + _currentApi.url;
	}
	return _currentApi;
}
/**
 * h5登录/公众号绑定手机号
 * @param phone
 * @param verifyCode
 * @param host
 */
async function getH5Login(
	phone = '',
	verifyCode = '',
	host = '',
): Promise<any> {
	if (!getSystemEnv()) {
		let _h5SignIn = getNewApi(h5SignIn, host);
		const res: any = await fetch({
			..._h5SignIn,
			data: {
				phone,
				verifyCode,
			},
		});
		const { code, data } = res;
		if (!code && data?.satoken) {
			//FIXME 开发环境
			if (window.location.origin.includes(':3000')) {
				window.localStorage.setItem('localSatoken', data.satoken);
			}

			getUserInfo(() => {
				// window.location.reload();
			}).catch(() => '');
		}

		return Promise.resolve(res);
	} else {
		let _bindPhone = getNewApi(bindPhone, host);
		const res: any = await fetch({
			..._bindPhone,
			data: {
				phone,
				verifyCode,
			},
		});
		const { code } = res;
		if (!code) {
			localStorage.setItem('phone', phone);
			Toast.success('绑定成功');
			getUserInfo().catch(() => '');
		} else {
			localStorage.removeItem('phone');
		}
		return Promise.resolve(res);
	}
}

/**
 * 取 cookie
 * @param name
 */
function getCookie(name: string): string | null {
	const nameLen = name.length + 1;
	return (
		document.cookie
			.split(';')
			.map((c) => c.trim())
			.filter((cookie) => {
				return cookie.substring(0, nameLen) === `${name}=`;
			})
			.map((cookie) => {
				return decodeURIComponent(cookie.substring(nameLen));
			})[0] || null
	);
}

/**
 * 微信签名
 */
async function getWxSignature(): Promise<any> {
	let _url = encodeURIComponent(window.location.href.split('#')[0]);
	const { code, data } = await fetch({
		...wxSignature,
		data: {
			url: _url,
		},
	});
	if (!code) {
		return Promise.resolve(data);
	}
}

/**
 * 取路由参数
 * @param key
 * @param url
 */
function getRouteKey(key: string, url?: string): string | null {
	const _url: string = url || window.location.search;
	const _obj: URLSearchParams = new URLSearchParams(_url);
	return _obj.get(key);
}

/**
 * obj to search
 * @param searchObj
 * @param flag
 */
function objToSearch(
	searchObj: {
		appGoodsUuid: string | null;
		mch: string | null;
		mpAppUuid: string | null;
	},
	flag = false,
) {
	const localSearch: any = {
		mch: getRouteKey('mch') || window.localStorage.getItem('mch'),
		mpAppUuid:
			getRouteKey('mpAppUuid') || window.localStorage.getItem('mpAppUuid'),
	};
	const _code = getRouteKey('code');
	const _localCode = window.localStorage.getItem('code');
	if (_code && _localCode && _code === _localCode) {
		localSearch.code = _localCode;
	}
	const _obj = flag
		? searchObj
		: Object.assign({}, searchObj || {}, localSearch);

	return '?' + new URLSearchParams(_obj).toString();
}

/**
 *
 * @param px
 */
function px2vw(px: number): string {
	const baseWidth = 750;
	return px ? Math.round((px / baseWidth) * 100000) / 1000 + 'vw' : '';
}

/**
 * 验证手机号
 * @param phone 手机号
 */
function isVerifyPhone(phone: string): boolean {
	const phoneReg = /^1[23456789]\d{9}$/;
	const _t = phone && phoneReg.test(phone);
	return _t as boolean;
}

function getOrderState(state: number = 1): any {
	const orderSate = {
		1: {
			name: '待付款',
		},
		2: {
			name: '等待卖家发货',
		},
		3: {
			name: '卖家已发货',
		},
		4: {
			name: '交易完成',
		},
		5: {
			name: '已取消',
		},
	};
	// @ts-ignore
	return orderSate[state];
}

/**
 * 取商品tip
 * @param subState
 */
function getSubState(subState: number): string {
	// @ApiModelProperty(value="子状态（下架（11:仓库中）上架（21:销售中，22:警戒，23:已售罄））")
	// private Integer subState;
	const _subState = {
		11: {
			tip: '商品已下架',
			btn: '已下架',
		},
		23: {
			tip: '商品已售罄～',
			btn: '已售罄',
		},
		99: {
			tip: '商家未绑定收款账号～',
			btn: '商家未绑定收款账号',
		},
	};

	//@ts-ignore
	return _subState[subState];
}

function setClipBoardText(value: any) {
	const text = document.createElement('textarea');
	text.value = value;
	document.body.appendChild(text);
	text.select();
	document.execCommand('Copy');
	text.remove();
}

function debug(response: any) {
	const { data, options } = JSON.parse(response);
	const _div = document.createElement('div');
	_div.setAttribute(
		'style',
		'padding: 20px; box-sizing: border-box; word-break: break-all; overflow-y: auto; background: rgb(204, 204, 204); position: fixed; inset: 0px; z-index: 1;',
	);
	_div.innerHTML = `<div style='padding-bottom: 10px;'>开发debug:点击复制到剪贴板并关闭</div>
                            <div style='padding-bottom: 10px'><div style='color:red;padding-bottom: 10px'>request header:</div> ${
															options && JSON.stringify(options)
														}</div>
                            <div style='padding-bottom: 10px;'><div style='color:red; padding-bottom: 10px;'>response: </div>${
															data && JSON.stringify(data)
														}</div>
`;
	_div.onclick = function () {
		setClipBoardText(response);
		_div.remove();
	};
	document.body.appendChild(_div);
}

/**
 * 是否是本地开发
 */
function isLocalDev(): boolean {
	return process.env.REACT_APP_ENV === 'devLocal';
}

const ItemObj: { [key: string]: any } = {
	10500: {
		img: 'https://qimai-1251581441.cos.ap-shanghai.myqcloud.com/qimai-h5/%E7%BC%96%E7%BB%84%203%402x%20%284%29.png',
		tip: '资源不存在',
	},
	10510: {
		img: 'https://qimai-1251581441.cos.ap-shanghai.myqcloud.com/qimai-h5/%E7%BC%96%E7%BB%84%202%402x.png',
		tip: '店铺维护中',
	},
	10410: {
		img: 'https://qimai-1251581441.cos.ap-shanghai.myqcloud.com/qimai-h5/%E7%BC%96%E7%BB%84%203%402x%20%282%29.png',
		tip: '没有权限',
	},
	10423: {
		img: 'https://qimai-1251581441.cos.ap-shanghai.myqcloud.com/qimai-h5/%E7%BC%96%E7%BB%84%203%402x%20%283%29.png',
		tip: '操作太频繁，稍后再试',
	},
	700410: {
		img: 'https://qimai-1251581441.cos.ap-shanghai.myqcloud.com/qimai-h5/%E7%BC%96%E7%BB%84%203%402x%20%284%29.png',
		tip: '资源不存在',
	},
	10400: {
		img: 'https://qimai-1251581441.cos.ap-shanghai.myqcloud.com/qimai-h5/%E7%BC%96%E7%BB%84%203%402x%20%284%29.png',
		tip: '资源不存在',
	},
	900410: {
		img: 'https://qimai-1251581441.cos.ap-shanghai.myqcloud.com/qimai-h5/%E7%BC%96%E7%BB%84%203%402x%20%284%29.png',
		tip: '资源不存在',
	},
};

function exceptionStore(code: number): void {
	const _boxStyle = `
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 100;
        background: #F2F2F2;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
    `;

	const J_exception = document.getElementById('J_exception') as HTMLElement;
	J_exception && J_exception.remove();

	const _imgBox = `width:${px2vw(320)};height:${px2vw(
		320,
	)};margin-bottom:${px2vw(66)}`;
	const _tipStyle = `font-size:${px2vw(32)};color:#333;`;

	const _div = document.createElement('div');
	_div.setAttribute('style', _boxStyle);
	_div.setAttribute('id', 'J_exception');
	_div.innerHTML = `
                    <div style=${_imgBox}>
                        <img src='${ItemObj[code]?.img}' style='width: 100%;
      height: 100%;' alt=''/>
                    </div>
                    <div style=${_tipStyle}>
                        ${ItemObj[code]?.tip}
                    </div>
                    `;

	document.body.appendChild(_div);
}

/**
 * search 转 object
 * @param searchString
 */
function searchToObj(searchString: string): { [key: string]: string } {
	if (!searchString) {
		return {};
	}
	return searchString
		.substr(1)
		.split('&')
		.reduce((pre, cur) => {
			pre[cur.split('=')[0]] = cur.split('=')[1];
			return pre;
		}, {} as { [key: string]: string });
}

/**
 * 微信登录是否有效
 */
async function wxIsLogin() {
	const _currentSearchObj = searchToObj(window.location.search);
	let _url: string;
	if (_currentSearchObj.hasOwnProperty('code')) {
		delete _currentSearchObj['code'];
	}
	_url =
		window.location.origin +
		window.location.pathname +
		objToSearch(_currentSearchObj as any);
	await fetch({
		...isLogin,
		data: {
			url: encodeURIComponent(_url),
		},
	});
}

function h5Init(cb?: Function) {
	let mch: string | null = getRouteKey('mch');
	let mpAppUuid: string | null = getRouteKey('mpAppUuid');

	const localMch = window.localStorage.getItem('mch');
	const localMpAppUuid = window.localStorage.getItem('mpAppUuid');

	if (mch && mpAppUuid && (mch !== localMch || mpAppUuid !== localMpAppUuid)) {
		window.localStorage.setItem('mch', mch);
		window.localStorage.setItem('mpAppUuid', mpAppUuid);
	}

	cb && cb();
}

async function getMchPhone(mchUuid: string): Promise<any> {
	const { code, data } = await fetch({
		...mchPhone,
		data: { mchUuid },
	});

	if (!code && data) {
		return Promise.resolve(data);
	} else {
		return Promise.resolve({ phone: '' });
	}
}

/**
 * 打电话
 * @param phone
 */
function phoneCall(phone: string | number) {
	let a = document.createElement('a');
	a.href = `tel:${phone}`;
	a.click();
}

/**
 * 压缩图片
 * @param img 原图片
 * @param model 模式
 */
function getPicForModel(img: string, model: string = 'q60') {
	enum modelMap {
		w375q60 = 'imageMogr2/thumbnail/375x/rquality/60',
		w100q75 = 'imageMogr2/thumbnail/100x/rquality/80',
		q60 = 'imageMogr2/rquality/60',
	}

	// @ts-ignore
	return `${img}?${modelMap[model]}`;
}

function priceFormat(price: number | string) {
	return price?.toString().split('.') || [];
}

/**
 * 根据环境取服务端域名
 */
function getHostEnv(): string {
	enum envHost {
		devLocal = '//api.qmdev1.qibcms.com', // C端手机H5端域名，本地开发环境
		// devLocal = 'http://192.168.101.102', // C端手机H5端域名，本地开发环境,凯勋电脑
		// devLocal= 'http://192.168.101.238:1103', // C端手机H5端域名，本地开发环境,保胜电脑
		// devLocal = '//api.fuwuapi.com', //线上测试环境
		yapi = 'http://yapi.wfx.wxfenxiao.com/mock/594', // yapi站点mock数据
		onLineTest = '//api.fuwuapi.com', //线上测试环境
		pro = '//api.qibo.com', //线上环境
	}

	let _host = '';
	// @ts-ignore
	_host = envHost[process.env.REACT_APP_ENV];
	return _host;
}

/**
 * 取默认头像
 * @param flag
 */
function getDefaultImg(flag = '0') {
	enum imgEnum {
		'0' = 'https://qimai-1251581441.cos.ap-shanghai.myqcloud.com/qimai-mp/undefined/1447750417667723264/2021-12-31/%E7%BC%96%E7%BB%84%E5%A4%87%E4%BB%BD%202_1640947911292c0876207.png',
	}
	return imgEnum[flag];
}

const Util: any = {
	debugger: window.location.origin.includes(':3000') ? false : true,
	appId: 'wx1fae6a3423c91cc8', //公众号ID
	setTitle,
	phoneCall,
	isIphoneX,
	needLogin,
	searchToObj,
	getSystemEnv,
	getWxCode,
	priceFormat,
	getRouteKey,
	objToSearch,
	getUserInfo,
	getMchPhone,
	getHostEnv,
	getSilentSignIn,
	getH5Login,
	getNewApi,
	h5Init,
	px2vw,
	isVerifyPhone,
	getOrderState,
	isLocalDev,
	getSubState,
	debug,
	exceptionStore,
	getWxSignature,
	wxIsLogin,
	getPicForModel,
	getCookie,
	userIsLogin,
	getDefaultImg,
};

export default Util;

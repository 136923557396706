import React from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom';
import './App.css';
import routerConfig from './router/routers';
import { NoMatch } from './components';

function App() {
	// console.log('routerConfig', routerConfig);

	return (
		<div className='App'>
			<React.Suspense fallback={<span></span>}>
				<Router>
					<Switch>
						{routerConfig.length > 0 &&
							routerConfig.map(({ path, component }, i) => (
								<Route key={i} exact path={path} component={component} />
							))}

						<Route
							exact
							path='/'
							render={() => {
								return <Redirect to={'/goodsDetail'} />;
							}}
						/>

						<Route
							path='*'
							exact
							render={({ match }) => {
								return <NoMatch />;
							}}
						/>
					</Switch>
				</Router>
			</React.Suspense>
		</div>
	);
}

export default App;

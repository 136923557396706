/**
 * Created by jingwen on 2021/8/19
 */
import { apiItem } from './apiList';
import axios, { AxiosRequestConfig } from 'axios';
import Util from './util';
import { Toast } from 'antd-mobile';

type Options =
	| {
			headers?: any;
			data?: any;
			type?: string;
			[propName: string]: any;
	  }
	| apiItem;

export async function fetch<T>(options: Options): Promise<T> {
	if (!options.url) {
		throw new Error('');
	}

	const satoken = Util.getCookie('satoken'); //||  "23757cd1-fb31-4763-9381-a822f19911be";
	options.url += `?t=${+new Date()}`;
	options.type =
		options.type === 'form'
			? 'application/x-www-form-urlencoded'
			: options.type;
	const mch = Util.getRouteKey('mch');
	const mpAppUuid = Util.getRouteKey('mpAppUuid');
	const localMch = window.localStorage.getItem('mch');
	const localMpAppUuid = window.localStorage.getItem('mpAppUuid');

	options.withCredentials = true;
	options.headers = Object.assign(
		{
			'content-type': options.type || 'application/json',
			cookieSatoken: satoken || '',
			mch: mch || localMch || null,
			mpAppUuid: mpAppUuid || localMpAppUuid || null,
			source: Util.getSystemEnv() ? 1 : 2,
			sourcePath: encodeURIComponent(window.location.href),
		},
		options.headers,
	);

	if (window.location.origin.includes(':3000')) {
		options.headers.satoken = window.localStorage.getItem('localSatoken');
	}

	console.log(options);
	const baseHost: string = Util.getHostEnv();
	options.url =
		options.url.includes('.com') || options.url.includes('360shop.cc')
			? options.url
			: baseHost + `/${options.server}${options.url}`;

	if (options.method === 'GET') {
		if (!options.data) {
			options.data = {};
		}
		options.params = options.data || {};
	}

	if (options.type === 'form') {
		options.data = new URLSearchParams(options.data || {}).toString();
	}

	try {
		return new Promise(async (resolve, reject) => {
			const response: any = await axios(options as AxiosRequestConfig);
			const { status, data } = response;

			if (status !== 200) {
				reject(data);
				return;
			} else {
				//线上测试环境
				// if (data?.code !== 0 && data?.msg) {
				// 	Toast.fail(data?.msg);
				// }
				resolve(data);
			}
		});
	} catch (error) {
		console.error(error);
		throw error;
	}
}

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import Util from './util/util';
import * as VConsole from 'vconsole';
// if (Util.debugger) {
// 	// @ts-ignore
// 	window.vConsole = new VConsole();
// }

ReactDOM.render(<App />, document.getElementById('root'));

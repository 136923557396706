import React from 'react';
import styles from './index.module.scss';
import {
	CustomIcon,
} from '../index';

interface PageProps {
	max: number;
	value: number;
	changeCallBack: (n: number) => void;
	blurCallBack: (event: any) => void;
	stockNum: number;
}

const Stepper: React.FC<PageProps> = ({
	max,
	value,
	changeCallBack,
	blurCallBack,
	stockNum,
}) => {
	// const [errorValue, setErrorValue] = React.useState<number>();
	// const [ignored, forceUpdate] = React.useReducer((x: number) => x + 1, 0);

	// const [buyNum, setBuyNum] = useState<number | undefined>(1);

	/**
	 * * 验证正整数
	 * @param s
	 * @param max
	 */
	function verifyBuyNum(s: string, max: number): number {
		const buyReg = /^[1-9]\d*$/;
		let _default: number | string = '1';
		if (!buyReg.test(s.toString())) {
			_default = +s < 0 ? 1 : '1';
		} else if (+s > max) {
			_default = max;
		} else {
			_default = +s;
		}
		return Number(_default);
	}
	return (
		<div className={styles.stockFunBox}>
			<div
				className={styles.icon}
				onClick={() => {
					if (value && value > 1) {
						// setBuyNum(+value - 1);
						changeCallBack(+value - 1);
					}
				}}>
				<CustomIcon
					type={'Mobile-Reduce'}
					size={18}
					color={value === 1 ? '#cbcbcb' : '#666'}
				/>
			</div>
			<div className={styles.input}>
				<input
					type='tel'
					value={value}
					onChange={(e) => {
						const _originNum = e.target.value;
						let _obj;
						if (!_originNum || _originNum.trim()) {
							_obj = verifyBuyNum(
								_originNum,
								Number(max),
							);
						}
						blurCallBack(_obj);
						// setBuyNum(_obj);
					}}
					onBlur={() => {
						if (!value) {
							// setBuyNum(1);
							changeCallBack(1);
						}
					}}
					min={1}
				/>
			</div>
			<div
				className={styles.icon}
				onClick={() => {
					if (value && value < max) {
						// setBuyNum(+value + 1);
						changeCallBack(+value + 1);
					}
				}}>
				<CustomIcon
					type={'Mobile-Increase'}
					size={18}
					color={
						value === max ? '#cbcbcb' : '#666'
					}
				/>
			</div>
		</div>
	);
};

export default Stepper;

import { FC } from 'react';
import { withRouter } from 'react-router-dom';

type Props = {
  [propName: string]: any;
};
const NoMatch: FC<Props> = (props) => {
  props.history.goBack();
  return null;
};

export default withRouter(NoMatch);

import * as React from 'react';
import { Image } from 'antd-mobile-v5';

const defaultImgUrl =
	'https://qimai-1251581441.cos.ap-shanghai.myqcloud.com/corsSource/default/goods.jpeg';
interface PageProps {
	src: string;
	defaultSrc?: string;
	[e: string]: any;
}

const BaseImage: React.FC<PageProps> = ({ src, defaultSrc, ...props }) => {
	const _defaultSrc = defaultSrc ?? defaultImgUrl;
	const [showSrc, setShowSrc] = React.useState(src || _defaultSrc);
	const [error, setError] = React.useState(false);
	React.useEffect(() => {
		setShowSrc(src || _defaultSrc);
		setError(false);
	}, [src, _defaultSrc]);
	if (error) {
		return (
			<span>
				<Image src={defaultImgUrl} {...props} />
			</span>
		);
	}
	return (
		<Image
			src={showSrc}
			onError={() => {
				setError(true);
			}}
			{...props}
		/>
	);
};

export default React.memo(BaseImage);

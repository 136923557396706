/**
 * Created by jingwen on 2021/8/18
 */

import React from 'react';

const GoodsDetail = React.lazy(
	() => import('./../pages/goodsDetail/goodsDetail'),
);
const SubmitOrder = React.lazy(
	() => import('./../pages/submitOrder/submitOrder'),
);
const AddressInfo = React.lazy(
	() => import('./../pages/addressInfo/addressInfo'),
);
const OrderDetail = React.lazy(
	() => import('./../pages/orderDetail/orderDetail'),
);
const OrderList = React.lazy(() => import('./../pages/orderList/orderList'));
const Logistics = React.lazy(() => import('./../pages/logistics/logistics'));
const ConfirmOrderSuccess = React.lazy(
	() => import('./../pages/confirmOrderSuccess/confirmOrderSuccess'),
);
const SearchResult = React.lazy(
	() => import('./../pages/searchResult/searchResult'),
);

const CustomPage = React.lazy(() => import('./../pages/customPage/customPage'));

const routerConfig = [
	{
		path: '/goodsDetail',
		component: GoodsDetail,
	},
	{
		path: '/submitOrder',
		component: SubmitOrder,
	},
	{
		path: '/addressInfo',
		component: AddressInfo,
	},
	{
		path: '/orderDetail',
		component: OrderDetail,
	},
	{
		path: '/orderList',
		component: OrderList,
	},
	{
		path: '/logistics',
		component: Logistics,
	},
	{
		path: '/confirmOrderSuccess',
		component: ConfirmOrderSuccess,
	},
	{
		path: '/searchResult',
		component: SearchResult,
	},
	{
		path: '/customPage',
		component: CustomPage,
	},
];

export default routerConfig;

/**
 * Created by jingwen on 2021/12/7
 */
//@ts-nocheck
/* eslint-disable */

import Countdown, { zeroPad } from 'react-countdown';
import { Fragment } from 'react';

interface Props {
	date: string | number;
	render?: (props: any) => any;
	onComplete?: () => void;
}

const CountDownBox = Countdown;

function CustomCountDown(props: Props) {
	const { date, render, onComplete } = props;

	return (
		<Fragment>
			<CountDownBox
				date={date}
				onComplete={onComplete}
				renderer={({ days, hours, minutes, seconds, completed }) => {
					if (render) {
						return render({
							days: zeroPad(days),
							hours: zeroPad(hours),
							minutes: zeroPad(minutes),
							seconds: zeroPad(seconds),
							completed,
						});
					} else {
						if (completed) {
							return <span>结束</span>;
						} else {
							return (
								<span>
									{zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:
									{zeroPad(seconds)}
								</span>
							);
						}
					}
				}}
			/>
		</Fragment>
	);
}

export default CustomCountDown;

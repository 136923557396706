/**
 * Created by jingwen on 2021/8/25
 */
import { FC, Fragment, ReactNode, useState } from 'react';
import styles from './skuDialog.module.scss';

type Position = 'bottom' | 'top' | 'center';

interface SkuDialogProps {
  onClose: () => void;
  visible: boolean;
  children: ReactNode;
  position?: Position;
}

const SkuDialog: FC<SkuDialogProps> = (props) => {
  const style = {
    bottom: { justifyContent: 'flex-end' },
    center: { justifyContent: 'center' },
    top: { justifyContent: 'flex-start' },
  };
  const [currentPosition] = useState<object>(() => {
    return props?.position ? style[props.position] : style.center;
  });

  return (
    <Fragment>
      {props?.visible && (
        <div
          className={`${styles.dialogBox}`}
          style={currentPosition}
          onClick={() => {
            props.onClose();
          }}
        >
          {props?.children}
        </div>
      )}
    </Fragment>
  );
};

SkuDialog.defaultProps = {
  visible: false,
  children: null,
  position: 'center',
  onClose: () => {},
};

export default SkuDialog;
